<template>
  <div>
    <b-card>
      <b-alert show variant="primary">
        <p class="text-center">
          <br />
          <strong>Você possui mais de 1 vínculo.</strong> Selecione uma área de trabalho ou volte
        </p>
        <div class="justify-content-center d-flex mt-2">
          <b-button variant="primary" class="mr-1" @click="openModal"> Mudar workspace </b-button>
          <b-button variant="warning" @click="$router.go(-1)"> Voltar </b-button>
        </div>
        <hr />
      </b-alert>
    </b-card>
    <div>
      <div class="auth-wrapper auth-v2">
        <b-modal
          v-model="modalWorkspace"
          hide-footer
          hide-header-close
          no-close-on-backdrop
          modal-class="modal-primary"
          centered
          title="Escolha áreas de trabalho"
        >
          <b-card-text>
            Existem diversas áreas de trabalho vinculadas ao seu usuário,
            <strong>selecione a desejada</strong>:
          </b-card-text>
          <v-select
            id="custon-select-local"
            @search="
              (search, loading) => {
                onFiltered(search);
              }
            "
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            placeholder="Selecione"
            v-model="workspaceSelected"
            :options="workspaces"
            :getOptionLabel="item => item.workspaces.workspace_title"
          />
          <b-row class="mt-2">
            <b-col class="justify-content-between d-flex">
              <b-button @click="closeModal" variant="warning">Cancelar</b-button>
              <b-button :disabled="!workspaceSelected" @click="setWorkspace" variant="primary">Selecionar</b-button>
            </b-col>
          </b-row>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { BRow, BCol, BModal, BCardText, BButton, BAlert, BCard } from 'bootstrap-vue';

export default {
  components: {
    BRow,
    BCol,
    BModal,
    BCardText,
    BButton,
    BAlert,
    BCard
  },
  data() {
    return {
      modalWorkspace: true,
      workspaceSelected: null
    };
  },
  computed: {
    multipleWorkspaces() {
      return this.$store.getters.multipleWorkspaces;
    },
    workspaces() {
      return this.$store.getters.workspaces;
    }
  },
  methods: {
    setWorkspace() {
      this.$store.commit('SET_CURRENT_WORKSPACE', this.workspaceSelected.workspaces);
      this.closeModal();
      this.$router.push({ name: 'home' });
    },
    openModal() {
      this.modalWorkspace = true;
    },
    closeModal() {
      this.modalWorkspace = false;
    }
  }
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
